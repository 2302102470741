.save-modal {
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  input {
    width: calc(100% - 22px);
    border: 1px solid #d0cece;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 10px;
    border-right: 0;
    pointer-events: none;
    outline: none;
		margin: 0;
    &::selection {
      background: transparent;
      outline: none;
    }
    
  }
  
  .input-group {
    display: flex;
    margin-bottom: 10px;
    .url {
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid #ddd;
      border-radius: 0;
    }
    .copy_url {
      display: block;
      padding: 15px 10px;
      border: 1px solid #d0cece;
      border-left: 0;
      text-transform: uppercase;
      text-decoration: none;

        color: #4E7A92;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
    }
  }
  h2 {
    margin-top: 0;
  }
  p {
    line-height: 1.5;
    margin-top: 0;
    text-align: center;
    padding: 20px 0 10px 0;
    margin: 0;
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      text-align: center;
  }
  .share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    a {
      display: flex;
      padding: 5px 20px;
      align-items: center;
      color: #333;
      text-decoration: none;
      background: #666;
      margin-bottom: 10px;
      color: #FFFFFF;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      &.tt {
        background: #55ADEF;
      }
      &.pin {
        background: #BD011F;
      }
      &.lin {
        background: #0073B1;
      }
      &.fb {
        background: #4267B2;
      }
      svg {
        width: 30px;
        margin-right: 30px;
        * {
          fill: #fff;
        }
      }
    }
  }
  .close__button {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .close__button_bottom {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }
  &-portal {
    .ReactModal__Overlay.ReactModal__Overlay--after-open {
      background-color: rgba(0,0,0,0.80) !important;
    }
    .ReactModal__Content {
      overflow: hidden;
      @media screen and (max-width: 767px) {
        height: calc(100vh - 76px) !important;
      }
    }
  }
}

