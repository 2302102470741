.material-select {
  display: flex;
  margin: 10px 0;
  margin-bottom: 0;
  @media screen and (max-width: 992px) {
    // margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    
  }
}


.material {
  border: 1px solid #e6e6e6;
  padding: 0 4px;
  flex: 1;
  min-width: 160px;
  min-height: 40px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  @media screen and (max-width: 1025px) {
    font-size: 13px;
  }
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
  img {
    width: 28px;
    max-height: 33px;
    margin-right: 12px;
    // border-radius: 50%;
    
  }
  &.pattern {
    min-width: auto;
    height: auto;
    padding: 10px;
    // width: calc(100% - 36px);
    img {
      border-radius: 0;
      height: 40px;
      width: auto;
    }
    
    .text {
      opacity: 0.48;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      margin: 0 auto;
      // max-width: 60px;
    }
    .information {
      display: flex;
      align-items: center;
      .pattern-name {
        color: #000000;
        // font-family: Europa;
        font-size: 16px;
        line-height: 19px;
      }
      .coverage {
          color: #000000;
          // font-family: Europa;
          font-size: 12px;
          line-height: 20px;
      }
    }
  }
  
}

.material {
  // .material img[src*="/mask-"] {
  //   filter: saturate(0) brightness(50%) contrast(100%);
  // }
  &-1 {
    .material img[src*="/mask-"] {
      filter: saturate(0) brightness(100%) contrast(100%);
    }
  }
  &-2 {
    .material img[src*="/mask-"] {
      filter: saturate(0) brightness(70%) contrast(100%);
    }
  }
  &-3 {
    .material img[src*="/mask-"] {
      filter: saturate(0) brightness(48%) contrast(100%);
    }
  }
  &-4 {
    .material img[src*="/mask-"] {
      filter: saturate(0) brightness(27%) contrast(100%);
    }
  }
}

.step:not(.pattern) {
  
  .input {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 9px;
    padding-bottom: 10px;
    .add-button {
      width: auto;
      margin: 0;
      margin-left: 50px;
      margin-top: 10px;
      @media screen and (max-width: 992px) {
        margin-left: 50px;
      }
      &.hidden {
        display: none;
      }
    }
    .materials-wrapper {
      width: calc(100% - 51px);
      @media screen and (max-width: 992px) {
        width: calc(100% - 54px);
      }
      @media screen and (max-width: 768px) {
        width: calc(100% - 55px);
      }
    }
    
    .material-select {
      width: calc(100% + 1px);
      margin-top: 1px;
      @media screen and (max-width: 992px) {
        width: calc(100% + 5px);
        &:not(:nth-child(1)) {
          margin-top: 10px;
        }
      }
      &.blocked {
        
        cursor: not-allowed;
        .material {
          pointer-events: none;
          // padding: 4px;
          margin-bottom: 5px;
          background: #F0F0F0;
        }
        .add {
          display: none;
        }
        &:hover {
          &:before {
            content: 'You have reached the maximum of 10 materials for your design.';
            position: absolute;
            // bottom: 75px;
            // right: 20px;
            bottom: 62px;
            left: 50px;
            color: #AB2A22;
            width: 100px;
            font-size: 12px;
            padding: 5px;
            background: rgba(#F4E7E6, 1);
          }
          &:after {
            content: '';
            position: absolute;
            // bottom: 70px;
            // z-index: 100;
            // right: 60px;
            bottom: 56px;
            z-index: 100;
            left: 75px;
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-top: 5px solid #F4E7E6;
          }
        }
      }
    }
    .zone {

      margin-right: 20px;
      padding-left: 20px;
      // margin-top: 22px;
      margin-top: 15px;
      width: 2%;
      text-align: left;
      &:before {
        content: '';
        background: #ccc;
        position: absolute;
        display: inline-block;
        width: 6px;
        // height: calc(100% - 20px);
        // top: 10px;
        height: calc(100% - 13px);
      top: 3px;
        left: 0px;
        // border-radius: 50%;
      }
      &-1 {
        &:before {
          background: #ccc;
        }
      }
      &-2 {
        &:before {
          background: #999;
        }
      }
      &-3 {
        &:before {
          background: #666;
        }
      }
      &-4 {
        &:before {
          background: #333;
        }
      }
    }
  }

  &.randomize {
    border-bottom: 1px solid var(--border-color);
    .materials-wrapper {
      // width: 100%;
    }
    .material-select {
      margin-top: 10px;

    }
    .input {
      border-bottom: 0;
    }
    .zone {
      margin-top: 22px;
      &:before {

        top: 10px;
        height: calc(100% + 46px);
      }
    }
    .add-button {
      margin-left: 100px;
      @media screen and (max-width: 992px) {
        margin-left: 50px;
      }
    }

    .inputs-group.materials-0 {
      .zone {
        margin-top: 22px;
        &:before {
          height: calc(100% - 20px);
        }
      }
    }
  }
  
  .input.oval {
    .material {
      img:not([src*='/mask']) {
        // clip-path: ellipse(25% 48% at 50% 50%);
        transform: rotate(90deg);
        max-height: 33px;
        margin-left: 8px;
        margin-right: 20px;
        width: auto;
      }
    }
  }

  
  // .input.diamond {
  //   .material {
  //     img {
  //       width: 30px;
  //       clip-path: url(#diamond-mask-small);
  //     }
  //   }
  // }
  .add {
    border-left: 1px solid #e6e6e6;
  }
}
// .input.diamond {
//   .material {
//     img {
//       clip-path: url(#diamond-mask-small);
//     }
//   }
// }



.percent {
  min-width: 60px;
  position: relative;
  display: flex;
  border: 1px solid #e6e6e6;
  border-left: 0;
  width: 64px;
  height: 40px;
  margin-right: 22px;

  &::after {
    content: "%";
    position: absolute;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  input {
    text-align: right;
    width: 55%;
    border: 0;
    border-left: 0;
    margin: 0;
    outline: none;
    padding-right: 25px;
    font-size: 16px;
  }
}
.error {
  .percent {
    input {
      color: #B80B0B !important; 
    }
    color: #B80B0B !important; 
  }
}

.modal__materials_header {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: calc(100% - 50px);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px 25px 0 25px;
  .select-grout {
    // display: none;
    width: 250px !important;
    margin-left: 20px;
    &__control {
      height: 38px;
    }
    &__indicator {
      background: #fff !important;
      svg {
        height: 21px;
      }
    }
    &__value-container {
      padding-left: 10px !important;
    }

    @media screen and (max-width: 767px) {
      display: block;
      width: 100% !important;
      margin-left: 0px;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 1px;
    background: #ccc;
    width: calc(100% - 50px);
    @media screen and (max-width: 996px) {
      width: calc(100% - 50px);
    }
  }
  h2 {
    // margin: 30px 0;
    @media screen and (max-width: 996px) {
      font-size: 22px;
      // margin: 0;
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      width: 50%;
      margin-top:10px;
    }
  }

  .links {
    display: flex;
    align-items: center;
    margin-left: 20px;
    @media screen and (max-width: 992px) {
      margin-right: 20px;
      margin-left: 0;
    }
    @media screen and (max-width: 767px) {
      margin-right: 0;
      width: 100%;
      order: 2;
    }
    
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      margin-left: 40px;

      @media screen and (max-width: 767px) {
        margin: 0 45px 20px 0;
      }
      a {
        text-decoration: none;
        color: #000;
      }
      li {
        padding-right: 20px;
      }
    }
  }
}



.apply_materials {
  margin-left: auto;
  margin-right: 0px;
  padding: 10px;
  border: 2px solid var(--primary-color);
  background-color: #FFFFFF;
  cursor: pointer;
    color: var(--primary-color);
    // font-family: 'Europa-Bold';
    font-weight: 700;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    &:hover {
      border-color: var(--primary-color-border);
    }
    @media screen and (max-width: 992px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      order: 1;
      width: 25%;
      span {
        display: none;
      }
      &.tick {
        width: 180px;
        &:before {
          content: '';
          height: 12px;
          width: 12px;
          background-image: url('/static/images/checkmark-black.png');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          padding-right: 20px;
          position: relative;
          display: inline-block;
        }
      }
    }
}

.modal__materials_content {
  height: calc(100% - 120px);
  // width: calc(100% + 20px);
  padding: 0 25px;
  // margin-bottom: 80px;
  margin-top: 77px;
  overflow: auto;
  &.diamond {
    .type:not(#house_blends) {
      img {
        clip-path: url(#diamond-mask);
      }
    }
  }

  
  @media screen and (max-width: 992px) {
    margin-top: 73px;
    width: calc(100% + 0px);
    max-width: 100%;
    box-sizing: border-box;
  }
  @media screen and (max-width: 767px) { 
    margin-top: 112px;
    
  }

  p.note {
    font-size: 12px;
    line-height: 15px;
  }
  .type {
    width: 100%;
    &.hidden {
      display: none !important;
    }
  }
  #house_blends {
    &.hidden {
      display: none;
    }
    .items {
      // justify-content: space-between;
    }
    .item {
      width: 110px;
      padding: 5px;
      margin: 10px 0px;
      text-align: center;
      @media screen and (max-width: 767px) {
        width: 100px;
        max-width: 90px;
      }
      &:after {
        display: none;
      }
      &:hover {
        &:after {
          display: block;
        }
      }
    }
    .item img {
      border-radius: 0;
      max-width: 100px;
      @media screen and (max-width: 767px) {
        max-width: 63px;
      }
    }
  }
  &.oval{
    .type:not(#house_blends) {
      .item img {
        width: 33px;
        transform: rotate(90deg);
        @media screen and (max-width: 767px) {
          // max-width: 50%;
        }
      }

    }
  }
  &.oval, &.diamond {
    .type#natural_stone {
      padding-bottom: 5%;
    }
    .type#porcelain {
      display: none;
    }
  }
  &.diamond {
    .type#glass {
      display: none;
    }
  }

  .content {
    padding-bottom: 10%;
  }
}
.percentages-wrapper {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}
.percentages {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: calc(100%);
  flex-direction: column;
  text-align: right;
  order: 2;

  .error {
    color: #B80B0B;
  }
  .percantage {
    margin-left: auto;
  }
}

.close__button {
  &--material {
    position: absolute !important;
    z-index: 2 !important;
    top: 0 !important;
    right: 0 !important;
    height: 100px;
    padding: 0 5px;
    background: #f2f2f2;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    img {
      // top: 50%;
      // transform: translateY(-50%);
    }
    @media screen and (max-width: 992px) {
      background: none;
      border: 0;
      right: 20px !important;
      top: 20px !important;
      height: auto;;
      svg {
        width: 1.5em !important;
      }
    }
  }
}

.close__footer {
  width: calc(100% - 20px);
  padding: 20px 10px;
  text-align: center;
  position: fixed;
  background: white;
  bottom: 0px;
  // left: -20px;
  // text-transform: uppercase;
  font-weight: 700;
  // cursor: pointer;
  // margin-top: 20px;
  border-top: 1px solid #ccc;
  @media screen and (max-width: 768px) {
    left: -20px;
    width: calc(100% + 20px);
    top: calc(100vh - 78px);
    border-top: 1px solid #ccc;
  }
  .apply_materials {
    display: inline-block;
  }
}
.materials-modal {
  #porcelain {
    // padding-bottom: 33%;
    @media screen and (max-width: 992px) {
      padding-bottom: 0px;
    }
  }
  .ReactModal__Content {
    height: 650px !important;
    .alert {
      font-size: 14px;
      padding: 8px 5px 5px 5px;
      color: #AB2A22;
      background: #F4E7E6;
      position: absolute;
      top: 100%;
      width: calc(100% - 60px);
    }

    
  }
  &.max-length {
    .modal__materials_content {
      margin-top: 110px;
    }
    .item:not(.used) {
      &:hover {
        border-color: transparent !important;
        &::before, &:after {
          display: none !important;
        }
      }
    }
  }  
}

@media screen and (max-width: 992px) {
  .ReactModal__Content.ReactModal__Content--after-open {
      width: 100vw;
      transform: translate(0px, 0%) !important;
      inset: 0 0vw auto auto !important;
      top: 0 !important;
      right: 0 !important;

  }
  .materials-modal {
    .ReactModal__Content {
      max-height: 100vh !important;
      height: 100vh !important;
      width: 100vw !important;
      
      .modal__materials_content {
        margin-bottom: 0;
        padding-top: 30px;
        
        .items {
          display: grid;
         
          grid-template-columns: 20% 20% 20% 20% 20%;
          
          .item {
            width: 80%;
          }
        }
        .type {
          &:after {
            width: 100%;
          }
        }
        
        #porcelain {
          margin-bottom: 80px;
          
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .materials-modal {
    
    .ReactModal__Content {
      // height: calc(100vh - 76px) !important;
      // padding-bottom: 40px !important;
      .modal__materials_header {
        padding: 10px;
        width: calc(100% - 20px);
        align-items: flex-start;
        flex-wrap: wrap;
        
        h2 {
          margin-bottom: 10px;
        }
        ul {
          display: none;
        }
        &:after {
          width: calc(100% - 20px);
        }
        .materials__indicators {
          width: 40px;
        }
      }
      .modal__materials_content {
        .items {
          grid-template-columns: 33% 33% 33%;
          // justify-content: space-between;
          justify-content: space-between;
          .item {
            margin: 10px 0;
          }
          &::after {
            content: "";
            width: 70px;
            margin: 10px 0;
            padding: 10px;
          }

        }
        
      }
    }
  }
}
