

.order-view {
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 40px auto;

    @media screen and (max-width: 767px) {
		flex-direction: column;
	}

    footer {
        width: 100%;
        border-top: 1px solid #000;
        p {
            margin: 30px 0;
            line-height: 32px;
        }
        a {
            display: block;
            margin: 0px 0;
            text-decoration: none;
            color: #4E7A92;
        }
    }

    .info__wrapper {
        width: calc(48% - 15px);
        padding: 0 15px 0 0px;
        .buttons {
            border: 0;
        }
        .inputs__wrapper {

        }
        @media screen and (max-width: 1024px) {
            width: calc(48% - 30px);
            padding: 0 15px 0 15px;
        }
        @media screen and (max-width: 767px) {
            width: calc(100% - 30px);
            margin: 0 auto;
            padding: 0 15px;
            
	    }
    }

    .prev {

        width: calc(52% - 15px);
        padding: 0 0 0 15px;
        @media screen and (max-width: 992px) {
            
        }
        @media screen and (max-width: 767px) {
            width: calc(100% - 30px);
            padding: 0 15px;
            .preview {
                padding: 0 !important;
            }
	    }

        h2,
        h4,
        h5 {
            text-align: left;
            padding: 0;
            margin: 0;
            // @media screen and (max-width: 767px) {
            //     padding: 0 10px;
            // }
        }

        h2 {
            padding-bottom: 10px;
            color: #000000;
            font-size: 40px;
            font-weight: 600;
            line-height: 43px;
            max-width: 90%;
        }

        h4 {
            padding-bottom: 5px;
            color: #000000;
            font-size: 16px;
            line-height: 20px;
            max-width: 90%;
        }
        h5 {
            padding-bottom: 20px;
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            max-width: 90%;
        }
        img {
            // width: 80%;
            // height: auto;
        }
        .preview {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            padding-right: 55px;

            .preview__container {
                min-width: 510px;
                @media screen and (max-width: 1025px) {
                    max-width: 470px;
                    margin: 0 auto;
                }
                @media screen and (max-width: 992px) {
                    min-width: auto;
                }
            }
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        border-bottom: 1px solid var(--border-color);
        
        &:first-child {
            border-top: 1px solid var(--border-color);
        }

    }

    .info.column {
        flex-direction: column;
    }

    .info .info__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
        color: #000000;
        font-size: 24px;
        line-height: 30px;
    }

    .info .info__title span:nth-child(2) {
        color: #000000;
        font-size: 16px;
        line-height: 20px;
        padding-top: 15px;
    }

    .info img {
        max-width: 80px;
        margin: 10px 0;
        display: block;
    }

    .info .info__material {
        display: flex;
        align-items: center;
        min-height: 30px;
        &.oval {

            img {
                transform: rotate(90deg);
                max-height: 33px;
                margin-left: 8px;
                margin-right: 8px;
                width: auto;
            }

        //   }
          
        //   &.diamond {

        //       img {
        //         clip-path: url(#diamond-mask-small);
        //       }

          }
          
    }

    .info .info__grout div {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        img {
            width: 30px;
            margin: 0;
            margin-right: 10px;
        }
    }

    .info .info__grout span {
        width: 30px;
        height: 30px;
        display: block;
        margin-right: 10px;
    }
    
    .info .info__material {
        margin-bottom: 10px;
    }
    .info .info__material span {
        margin-left: 10px;
    }
    .info .info__material span:first-child {
        width: auto;
        min-width: 50px;
        margin-right: 10px;
        margin-left: 0;
        text-align: left;
    }

    .info .info__material img {
        width: 30px;
        /* margin-left: 30px; */
        // border-radius: 50%;
        margin: 0;
    }

    .info .info__materials {
        padding-top: 10px;
        &.randomized {
            .info__material span:first-child {
                min-width: 25px;
            }
            .info__material {
                margin-bottom: 15px;
            }
        }
    }

    .info .info__spec {
        display: flex;
        flex-direction: column;
    }

    .info .info__spec .item-spec {
        width: 100%;
        display: flex;
        margin: 10px 0;
        padding: 0;
        justify-content: space-between;
    }

    .info .info__spec .item-spec span:first-child {
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        line-height: 27px;
    }
    .info .info__spec .item-spec div span:first-child {
        font-weight: normal;
        font-size: 16px;
    }

    .info .info__spec .item-spec.application span:not(:nth-child(1)) {
        padding-left: 20px;
    }

    .info .info__spec .item-spec.application span:not(.include):not(:nth-child(1)) {
        color: rgba(0,0,0,0.3);
        display: inline-block;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top:60%;
            left: 35%;
            height: 1px;
            background: black;
            transform: translateY(-50%) rotate(-45deg);
            width: 70%;
        }
    }

    .preview__buttons {
        // display: none;
        min-width: 100%;
        @media screen and (max-width: 992px) {
            min-width: 100%
        }
        @media screen and (max-width: 768px) {
            min-width: 80%
        }
        @media screen and(max-width: 343px) {
            // width: 100%;
            width: calc(100% - 2px);
            left: 1px;
            margin: 0;
            bottom: 75px;
          }
    }

    .inputs__wrapper {
        width: 100%;
        p {
            text-align: center;
        }
    }

    .input-btn-groups {
        .button {
            width: calc(50% - 7.5px);
            background: transparent;
            color: var(--primary-color);
            height: 60px;
            &:hover {
                color: white;
            }
        }
    }

    .zone-wrapper {
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            border-bottom: 1px solid #ddd;
            width: calc(100%);
            left: 0px;
        }
        > span {
            width: 25px;
            display: block;
            position: relative;
            padding-top: 15px;
            padding-left: 5px;
            &:before {
                content: '';
                background: #ccc;
                position: absolute;
                display: inline-block;
                width: 6px;
                height: calc(100% - 5px);
                top: 0px;
                left: 0px;
                // border-radius: 50%;
              }
        }

        &:nth-child(1) {
            > span:before {
              background: #ccc;
            }
          }
          &:nth-child(2) {
            > span:before {
              background: #999;
            }
          }
          &:nth-child(3) {
            > span:before {
              background: #666;
            }
          }
          &:nth-child(4) {
            > span:before {
              background: #333;
            }
          }
        &:last-child {
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
        .percent-material {
            margin-left: auto !important;
        }
    }

    .randomized {
        padding-bottom: 15px;
        .zone-wrapper {
            margin: 0;
            padding: 0;
            >span {
                display: none;
            }
            &:after {
                display: none;
            }

            .info__material {
                margin-bottom: 0;
            }
            .materials--wrapper {
                padding-bottom: 0;
                padding-left: 0;
            }
        }
    }

    .materials--wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px 5px;
        width: 100%;
    }
}

.loading-page {
    
    // width: 100vw;
    // height: 100vh;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        transform: translate(-50%, -50%);
    }
}