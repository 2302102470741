.images_build {
	display: none;
}
svg {
	width: 100%;
	min-width: auto !important;
	position: relative;
}
.preview__container  {
	position: relative;
	.loading {
		position: absolute;
		width: 100%;
		height: 100%;
		&::before {
			z-index: 1;
		}
		svg {
			top: 50%;
			z-index: 2;
			position: absolute;
			height: 60px;
			width: 60px;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
.preview__wrapper {
	padding-bottom: 0px;
	padding-top: 0px;
	// border: 1px solid var(--border-color);
	overflow: hidden;

  }
  .svg__wrapper {
	max-height: 600px;
	position: relative;
	// min-height: 450px;
	@media screen and (max-width: 992px) {
		min-height: auto;
	}
	@media screen and (max-width: 767px) {
		// min-height: 450px;
	}
	
  }
  .svg__wrapper.single div:not(:first-child){
	opacity: 0;
  }
  
  .svg__wrapper svg {
	min-width: 300px;
	max-width: calc(100% - 40px);
	height: auto;
	background: transparent;
	
  }

  
.svg__wrapper,
.preview__wrapper {
	min-width: 510px;
	@media screen and (max-width: 1056px) {
		min-width: 100%
	}
	@media screen and (max-width: 992px) {
		min-width: 100%;
	}
	&.single {
		margin-top: 10px;
		margin-bottom: 10px;
		background-color: transparent !important;
		.kitchen-wrapper {
			display: none;
		}
		// .repeatSVG {
		// 	display: none;
		// }
	}
	&.preview-single {
		background-color: #eee !important;
	}
	.repeatSVG {
		// transform: scale(1.6) translate(-25%, 25%);
	}
	&.repeat {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		width: 800px;
		margin-left: -10%;
		margin-top: -20%;
		height: 420px;
		max-height: 510px;
		
		@media screen and (max-width: 992px) {
			margin-left: -18%;
			margin-top: -35%;
		}
		.kitchen-wrapper {
			display: none;
		}
		.singleSVG {
			display: none;
		}
		.repeatSVG {
			width: 120%;
			// transform: scale(1.6) translate(-25%, 25%);
		}
		svg {
			max-width: 175%;
			width: 175%;
			margin: 0;
			margin-top: 5%;
		}

		// > div {
		// 	width: 25%;
		// 	min-width: 20%;
		// 	margin-top: -28px;
			

		// 	&:nth-child(2n) {
		// 		transform: translate(-25%, calc(50% - 14px));
		// 	}

		// 	&:nth-child(3),
		// 	&:nth-child(7) {
		// 		margin-left: -12.5%;
		// 	}

		// 	&:nth-child(11) {
		// 		margin-left: -12.5%;
		// 	}
			
		// }

		// &.Button15x17 {
		// 	>div {
		// 		margin-top: -25px;
				
		// 		&:nth-child(2n) {
		// 			transform: translate(-5.5px, 0);
		// 		}

		// 		&:nth-child(3),
		// 		&:nth-child(7),
		// 		&:nth-child(11) {
		// 			transform: translate(44.4%, 0);
		// 		}

		// 		&:nth-child(7) {
		// 			transform: translate(47%, 9.4%);
		// 		}

		// 		&:nth-child(4),
		// 		&:nth-child(8),
		// 		&:nth-child(12) {
		// 			transform: translate(41%, 0);
		// 		}

		// 		&:nth-child(5) {
		// 			transform: translate(3%, 9.4%);
		// 		}
				
		// 		&:nth-child(6) {
		// 			transform: translate(0%, 9.4%);
		// 		}
				
		// 		&:nth-child(8) {
		// 			transform: translate(44%, 9.4%);
		// 		}
				
		// 		&:nth-child(9) {
		// 			transform: translate(0%, 18.8%);
		// 		}
				
		// 		&:nth-child(10) {
		// 			transform: translate(-3%, 18.8%);
		// 		}
				
		// 		&:nth-child(11) {
		// 			transform: translate(44%, 18.8%);
		// 		}
				
		// 		&:nth-child(12) {
		// 			transform: translate(41%, 18.8%);
		// 		}
		// 	}
		// }
	}

	&.kitchen {
		width: 100%;
		// max-width: 330px;
		min-height: 330px;
		// > div {
		// 	svg {
		// 		display: none;
		// 		// position: relative;
		// 		// top: 0;
		// 		// left: 0;
		// 	}
		// }
		.singleSVG {
			display: none;
		}
		.repeatSVG {
			transform: scale(1.5) translate(0%, 10%);
			@media screen and (max-width: 992px) {
				position: absolute;
			}
		}
		.kitchen-wrapper {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// @media screen and (max-width: 992px) {
			// 	height: 400px;
			// }
		}
		svg, img {
			// max-width: 100%;
			width: 530px;
			max-width: none;
		}
		.room {
			// padding-top: 23%;
			
			// padding-top: 163px;
			transform: scale(1);
			opacity: 1 !important;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			width: 580px;
			max-height: 280px;
			margin-left: -15px;

			display: none !important;
		}
		
	}

	#grout,
	#grout-1 {
		fill: #fff;
	}

	&.single {
		#grout,
		#grout-1 {
			fill: #eee;
			&.none {
				// fill: #ccc !important;
			}
		}
		div:not(:first-child) {
			position: absolute;
		}
	}
}

