.order-modal {
  h2 {
    margin-top: 0;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      width: calc(100% - 22px);
      border: 1px solid #d0cece;
      color: black;
      font-size: 14px;
      line-height: 18px;
      padding: 15px 10px;
      margin-bottom: 10px;
      margin-top: 0;
      position: relative;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      &::placeholder {
        color: #898989;
      }
      &.error {
        border-color: red;
        + span {
          position: relative;
          bottom: 5px;
          font-size: 10px;
          right: 0;
          margin-left: auto;
          color: red;
        }
      }
     
      
    }
    .address {
      input {
        &.error {
          border-color: red;
          + span {
            position: relative;
            bottom: 12px;
            font-size: 10px;
            right: 0;
            margin-left: 85%;
            color: red;
          }
        }
        &[name="zip"], &[name="state"] {
          position: relative;
          &.error {
            + span {
              position: absolute;
              bottom: -5px;
              font-size: 10px;
              right: 0;
              margin-left: auto;
              color: red;
            }
          }
        }
        &[name="state"] {
          &.error {
            + span {
              left: 0;
              margin-right: auto;
            }
          }
        }
      }
      
      .select-grout {
        .select-grout__control {
          margin-top: 0;
          margin-right: 10px;
          border-color: #d0cece !important;
          .select-grout__value-container {
            padding-left: 10px !important;
          }
          .select-grout__single-value {
            color: #898989;
          }
          .select-grout__indicator {
            border-left: 1px solid #d0cece !important;
            border-color: #d0cece !important;
          }
          svg {
            height: 35px;
          }
        }
        

        &[name="state"] {
          &.error {
            + span {
              left: 0;
              margin-right: auto;
            }
          }
        }
      }
      .select-grout__menu {
        top: auto;
        bottom: 100%;
      }
    }
    .select {
      width: 100%;

      &.error {
        position: relative;
        .select-grout__control {
          border-color: red !important;
        }
        + span {
          position: relative;
          bottom: 12px;
          font-size: 10px;
          right: 0;
          margin-left: auto;
          color: red;
        }
      }
  
      &.error {
        + span {
          position: absolute;
          bottom: -5px;
          font-size: 10px;
          right: 0;
          margin-left: 50.5%;
          color: red;
          left: 0;
        }
      }
    }
    p.info-tip {
      font-size: 12px;
      position: relative;
      top: -5px;
      margin: 0;
    }
    textarea {
      resize: none;
    }

    button {
      -webkit-appearance: none;
      padding: 10px 10px;
      background: var(--primary-color);
      border: 0;
      color: white;
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      outline: none;
      cursor: pointer;
    }
  }
}

.close__button--order {
  position: absolute;
  top: 20px;
  right: 20px;
}

.order-modal-portal {
  .ReactModal__Content {
    min-height: 250px;
    // padding: 30px !important;
    max-height: 80vh !important;
    @media screen and (max-width: 992px) {
      max-width: 100% !important;
      min-height: 100vh !important;
      max-height: 100% !important;
    }
    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      min-height: 100vh !important;
      height: 100%;
      max-height: 100% !important;
    }
  }
  .order-modal {
    @media screen and (max-width: 767px) {
      margin-bottom: 100px;
    }
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  &.reset {
    .ReactModal__Content {
      min-height: auto;
      top: 50% !important;
      transform: translateY(-50%);
      @media screen and (max-width: 767px) {
        top: 0 !important;
        transform: translateY(0);
      }
    }
    .form {
      div {
        background: var(--primary-color);
        padding: 20px;
        color: white;
        width: 65%;
        margin: 10px 0;
        @media screen and (max-width: 767px) {
          width: calc(100% - 40px);
        }
        span {
          display: block;
          font-size: 24px;
          font-weight: 700;
          padding-bottom: 5px;
        }
        font-size: 16px;
      }
    }
    .last {
      margin-bottom: 0;
      a {
        color: black;
      }
    }
  }

  .radios {
    display: flex;
    margin: 10px 0 5px;

    .container {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      margin-right: 20px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 15px;
      width: 15px;
      // background-color: var(--primary-color);
      border-radius: 50%;
      border: 1px solid #d0cece;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: white;
      
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
       top:3px;
      left:3px;
      width:9px;
      height:9px;
      border-radius: 50%;
      background: var(--primary-color);;
    }
  }

  .checkbox-wrapper {

    .container {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: white;
      border: 1px solid #d0cece;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: white;
      
    }
    
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // background-color: white;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid var(--primary-color);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .address {
    .group {
      display: flex;

      position: relative;
      margin-bottom: 10px;
      input {
        &[name="state"] {
          width: calc(60% - 10px);
          margin-right: 10px;
        }
        &[name="zip"] {
          width: 40%;
        }
      }
    }
  }
}
